<template>
  <ak-container card :bodyStyle="{ padding: 0 }" class="ak-card-table">
    <a-tabs v-model="userType" default-active-key="0" @change="tabsChange">
      <a-tab-pane key="0" tab="用户认证审核">
        <a-space direction="vertical" :size="20">
          <a-card>
            <a-form-model
              ref="form"
              class="ak-query-filter"
              :model="queryFilters"
              :label-align="'left'"
              :labelCol="{ span: 12, offset: 1 }"
              :colon="false"
            >
              <a-row :gutter="24">
                <a-col v-bind="$wrapperThreeCol">
                  <a-form-model-item label="手机号" prop="phone">
                    <a-input
                      allowClear
                      v-model="queryFilters.phone"
                      placeholder="请输入"
                    />
                  </a-form-model-item>
                </a-col>

                <a-col v-bind="$wrapperThreeCol">
                  <a-form-model-item label="昵称" prop="name">
                    <a-input
                      allowClear
                      v-model="queryFilters.name"
                      placeholder="请输入"
                    />
                  </a-form-model-item>
                </a-col>

                <a-col v-bind="$wrapperThreeCol">
                  <a-form-model-item label="认证类型" prop="authenticationType">
                    <a-select
                      allowClear
                      v-model="queryFilters.authenticationType"
                      placeholder="请选择"
                    >
                      <a-select-option
                        v-for="item in typeOpt.userTypes"
                        :key="item.value"
                        :value="item.value"
                      >
                        {{ item.text }}
                      </a-select-option>
                    </a-select>
                  </a-form-model-item>
                </a-col>
              </a-row>
              <a-row :gutter="24">
                <a-col v-bind="$wrapperThreeCol">
                  <a-form-model-item label="状态" prop="applyState">
                    <a-select
                      allowClear
                      v-model="queryFilters.applyState"
                      placeholder="请选择"
                    >
                      <a-select-option
                        v-for="item in statusOpts"
                        :key="item.value"
                        :value="item.value"
                        >{{ item.label }}
                      </a-select-option>
                    </a-select>
                  </a-form-model-item>
                </a-col>

                <a-col v-bind="$wrapperThreeCol">
                  <a-form-model-item label="申请时间" prop="settleTime">
                    <a-range-picker
                      v-model="queryFilters.settleTime"
                      show-time
                      class="settime"
                      :placeholder="['开始日期', '结束日期']"
                      format="YYYY-MM-DD"
                      valueFormat="YYYY-MM-DD"
                    />
                  </a-form-model-item>
                </a-col>

                <a-col
                  v-bind="$wrapperThreeCol"
                  class="action-btn-main"
                  style="display: flex"
                >
                  <a-button type="primary" class="ML12" @click="search"
                    >查询</a-button
                  >
                  <a-button class="ML12" @click="onResetForm">重置</a-button>
                </a-col>
              </a-row>
              <a-button
                type="primary"
                class="ML12"
                @click="modal.phoneVisible = true"
                >新增认证</a-button
              >
            </a-form-model>
          </a-card>
          <a-table
            ref="table"
            :pagination="pagination"
            @change="handleTableChange"
            :columns="columns"
            :data-source="tableList"
            :scroll="{ x: 100 }"
            :loading="loading"
            :rowKey="(record) => record.id"
          >
            <!-- 用户信息 -->
            <template #bindPersonalResp="text">
              <a-row type="flex" align="middle">
                <a-col :span="12">
                  <img :src="text.headImage" class="headImage" alt="" />
                </a-col>
                <a-col :span="12" style="text-align: right">
                  {{ text.name }}<br />{{ text.phone }}
                </a-col>
              </a-row>
            </template>

            <!-- 认证类型 -->
            <template #authenticationType="text">
              <span v-for="(item, index) in allType" :key="index">{{
                item.value == text ? item.text : ''
              }}</span>
            </template>
            <!-- 状态 -->
            <template #state="text">
              <span v-if="text === 0" style="color: red">审核中</span>
              <span v-if="text === 1">已通过</span>
              <span v-if="text === 2" style="color: red">未通过</span>
            </template>

            <!-- 操作 -->
            <template #action="text, record">
              <a-button type="link" @click="sendInsertdetail(record)"
                >详情</a-button
              >
              <a-button
                v-if="record.state === 0"
                type="link"
                @click="audit(record)"
                >审核</a-button
              >
              <a-button
                v-if="record.state != 0"
                type="link"
                @click="sendInsertedit(record)"
                >重新认证
              </a-button>
            </template>
          </a-table>
        </a-space>
      </a-tab-pane>
      <a-tab-pane key="1" tab="创作者认证审核" force-render>
        <a-space direction="vertical" :size="20">
          <a-card>
            <a-form-model
              ref="form"
              class="ak-query-filter"
              :model="queryFilters"
              :label-align="'left'"
              :labelCol="{ span: 12, offset: 1 }"
              :colon="false"
            >
              <a-row :gutter="24">
                <a-col v-bind="$wrapperThreeCol">
                  <a-form-model-item label="手机号" prop="phone">
                    <a-input
                      allowClear
                      v-model="queryFilters.phone"
                      placeholder="请输入"
                    />
                  </a-form-model-item>
                </a-col>

                <a-col v-bind="$wrapperThreeCol">
                  <a-form-model-item label="昵称" prop="name">
                    <a-input
                      allowClear
                      v-model="queryFilters.name"
                      placeholder="请输入"
                    />
                  </a-form-model-item>
                </a-col>

                <a-col v-bind="$wrapperThreeCol">
                  <a-form-model-item label="认证类型" prop="authenticationType">
                    <a-select
                      allowClear
                      v-model="queryFilters.authenticationType"
                      placeholder="请选择"
                    >
                      <a-select-option
                        v-for="item in typeOpt.creativeTypes"
                        :key="item.value"
                        :value="item.value"
                      >
                        {{ item.text }}
                      </a-select-option>
                    </a-select>
                  </a-form-model-item>
                </a-col>
              </a-row>
              <a-row :gutter="24">
                <a-col v-bind="$wrapperThreeCol">
                  <a-form-model-item label="状态" prop="applyState">
                    <a-select
                      allowClear
                      v-model="queryFilters.applyState"
                      placeholder="请选择"
                    >
                      <a-select-option
                        v-for="item in statusOpts"
                        :key="item.value"
                        :value="item.value"
                        >{{ item.label }}
                      </a-select-option>
                    </a-select>
                  </a-form-model-item>
                </a-col>

                <a-col v-bind="$wrapperThreeCol">
                  <a-form-model-item label="申请时间" prop="settleTime">
                    <a-range-picker
                      v-model="queryFilters.settleTime"
                      show-time
                      class="settime"
                      :placeholder="['开始日期', '结束日期']"
                      format="YYYY-MM-DD"
                      valueFormat="YYYY-MM-DD"
                    />
                  </a-form-model-item>
                </a-col>

                <a-col
                  v-bind="$wrapperThreeCol"
                  class="action-btn-main"
                  style="display: flex"
                >
                  <a-button type="primary" class="ML12" @click="queryData"
                    >查询</a-button
                  >
                  <a-button class="ML12" @click="onResetForm">重置</a-button>
                </a-col>
              </a-row>
              <a-button
                type="primary"
                class="ML12"
                @click="modal.phoneVisible = true"
                >新增认证</a-button
              >
            </a-form-model>
          </a-card>
          <a-table
            ref="table"
            :pagination="pagination"
            @change="handleTableChange"
            :columns="columns1"
            :data-source="tableList"
            :scroll="{ x: 100 }"
            :loading="loading"
            :rowKey="(record) => record.id"
          >
            <template #logo="text">
              <img
                v-if="text !== ''"
                :src="`${$const.OssImagePrefix}/${text}`"
                class="headImage"
                alt=""
              />
            </template>
            <!-- 用户信息 -->
            <template #bindPersonalResp="text">
              <a-row type="flex" align="middle">
                <a-col :span="12">
                  <img :src="text.headImage" class="headImage" alt="" />
                </a-col>
                <a-col :span="12" style="text-align: right">
                  {{ text.name }}<br />{{ text.phone }}
                </a-col>
              </a-row>
            </template>

            <!-- 认证类型 -->
            <template #authenticationType="text">
              <span v-for="(item, index) in allType" :key="index">{{
                item.value == text ? item.text : ''
              }}</span>
            </template>
            <!-- 状态 -->
            <template #state="text">
              <span v-if="text === 0" style="color: red">审核中</span>
              <span v-if="text === 1">已通过</span>
              <span v-if="text === 2" style="color: red">未通过</span>
            </template>

            <!-- 操作 -->
            <template #action="text, record">
              <a-button type="link" @click="sendInsertdetail(record)"
                >详情
              </a-button>
              <a-button
                v-if="record.state === 0"
                type="link"
                @click="audit(record)"
                >审核</a-button
              >
              <a-button
                v-if="record.state != 0"
                type="link"
                @click="sendInsertedit(record)"
                >重新认证
              </a-button>
            </template>
          </a-table>
        </a-space>
      </a-tab-pane>
      <a-tab-pane key="2" tab="独立店认证审核">
        <a-space direction="vertical" :size="20">
          <a-card>
            <a-form-model
              ref="form"
              class="ak-query-filter"
              :model="queryFilters"
              :label-align="'left'"
              :labelCol="{ span: 12, offset: 1 }"
              :colon="false"
            >
              <a-row :gutter="24">
                <a-col v-bind="$wrapperThreeCol">
                  <a-form-model-item label="手机号" prop="phone">
                    <a-input
                      allowClear
                      v-model="queryFilters.phone"
                      placeholder="请输入"
                    />
                  </a-form-model-item>
                </a-col>

                <a-col v-bind="$wrapperThreeCol">
                  <a-form-model-item label="昵称" prop="name">
                    <a-input
                      allowClear
                      v-model="queryFilters.name"
                      placeholder="请输入"
                    />
                  </a-form-model-item>
                </a-col>

                <a-col v-bind="$wrapperThreeCol">
                  <a-form-model-item label="认证类型" prop="authenticationType">
                    <a-select
                      allowClear
                      v-model="queryFilters.authenticationType"
                      placeholder="请选择"
                    >
                      <template v-if="userType === '0'">
                        <a-select-option
                          v-for="item in typeOpt.userTypes"
                          :key="item.value"
                          :value="item.value"
                        >
                          {{ item.text }}
                        </a-select-option>
                      </template>
                      <template v-if="userType === '1'">
                        <a-select-option
                          v-for="item in typeOpt.creativeTypes"
                          :key="item.value"
                          :value="item.value"
                        >
                          {{ item.text }}
                        </a-select-option>
                      </template>
                      <template v-if="userType === '2'">
                        <a-select-option
                          v-for="item in typeOpt.independentTypes"
                          :key="item.value"
                          :value="item.value"
                        >
                          {{ item.text }}
                        </a-select-option>
                      </template>
                    </a-select>
                  </a-form-model-item>
                </a-col>
              </a-row>
              <a-row :gutter="24">
                <a-col v-bind="$wrapperThreeCol">
                  <a-form-model-item label="状态" prop="applyState">
                    <a-select
                      allowClear
                      v-model="queryFilters.applyState"
                      placeholder="请选择"
                    >
                      <a-select-option
                        v-for="item in statusOpts"
                        :key="item.value"
                        :value="item.value"
                        >{{ item.label }}
                      </a-select-option>
                    </a-select>
                  </a-form-model-item>
                </a-col>

                <a-col v-bind="$wrapperThreeCol">
                  <a-form-model-item label="申请时间" prop="settleTime">
                    <a-range-picker
                      v-model="queryFilters.settleTime"
                      show-time
                      class="settime"
                      :placeholder="['开始日期', '结束日期']"
                      format="YYYY-MM-DD"
                      valueFormat="YYYY-MM-DD"
                    />
                  </a-form-model-item>
                </a-col>

                <a-col
                  v-bind="$wrapperThreeCol"
                  class="action-btn-main"
                  style="display: flex"
                >
                  <a-button type="primary" class="ML12" @click="queryData"
                    >查询</a-button
                  >
                  <a-button class="ML12" @click="onResetForm">重置</a-button>
                </a-col>
              </a-row>
              <a-button
                type="primary"
                class="ML12"
                @click="modal.phoneVisible = true"
                >新增认证</a-button
              >
            </a-form-model>
          </a-card>
          <a-table
            ref="table"
            :pagination="pagination"
            @change="handleTableChange"
            :columns="columns2"
            :data-source="tableList"
            :scroll="{ x: 100 }"
            :loading="loading"
            :rowKey="(record) => record.id"
          >
            <template #logo="text">
              <img
                v-if="text !== ''"
                :src="`${$const.OssImagePrefix}/${text}`"
                class="headImage"
                alt=""
              />
            </template>
            <template #logo="text">
              <a-row type="flex" align="middle">
                <a-col :span="12">
                  <img
                    v-if="text"
                    :src="$const.OssImagePrefix + '/' + text"
                    class="headImage"
                    alt=""
                  />
                </a-col>
              </a-row>
            </template>
            <!-- 用户信息 -->
            <template #bindPersonalResp="text">
              <a-row type="flex" align="middle">
                <a-col :span="12">
                  <img :src="text.headImage" class="headImage" alt="" />
                </a-col>
                <a-col :span="12" style="text-align: right">
                  {{ text.name }}<br />{{ text.phone }}
                </a-col>
              </a-row>
            </template>

            <!-- 认证类型 -->
            <template #authenticationType="text">
              <span v-for="(item, index) in allType" :key="index">{{
                item.value == text ? item.text : ''
              }}</span>
            </template>
            <!-- 状态 -->
            <template #state="text">
              <span v-if="text === 0" style="color: red">审核中</span>
              <span v-if="text === 1">已通过</span>
              <span v-if="text === 2" style="color: red">未通过</span>
            </template>

            <!-- 操作 -->
            <template #action="text, record">
              <a-button type="link" @click="sendInsertdetail(record)"
                >详情
              </a-button>
              <a-button
                v-if="record.state === 0"
                type="link"
                @click="audit(record)"
                >审核</a-button
              >
              <a-button
                v-if="record.state != 0"
                type="link"
                @click="sendInsertedit(record)"
                >重新认证
              </a-button>
            </template>
          </a-table>
        </a-space>
      </a-tab-pane>
    </a-tabs>
    <a-modal
      :visible="modal.phoneVisible"
      :footer="null"
      :afterClose="afterClose"
      @cancel="afterClose"
      width="35%"
    >
      <template #title>
        新增认证用户<span
          style="font-size: 12px; color: rgba(0, 0, 0, 0.3); margin-left: 15px"
          >搜素用户账号</span
        >
      </template>
      <div style="display: flex; justify-content: center; flex-wrap: wrap">
        <a-input
          style="width: 100%; margin: 8%"
          size="large"
          v-model="userPhone"
          placeholder="请输入用户手机号"
        />
        <a-button
          style="width: 30%; margin: 5% 0 8% 0"
          type="primary"
          @click="checkPhone"
          >查询</a-button
        >
      </div>
    </a-modal>
    <a-modal
      :visible="modal.infoVisible"
      @cancel="afterCloseinfo"
      :footer="null"
      width="80%"
    >
      <template #title>
        新增认证用户<span
          style="font-size: 12px; color: rgba(0, 0, 0, 0.3); margin-left: 15px"
          >确认用户账号</span
        >
      </template>
      <a-table
        ref="infoTable"
        :rowKey="(record) => record.id"
        size="small"
        :pagination="false"
        :columns="infoColumns"
        :data-source="infoData"
      >
        <template #sex="text">
          <span>{{ text == '1' ? '男' : '女' }}</span>
        </template>
        <template #headImage="text">
          <img :src="text" class="headImage" alt="" />
        </template>
        <template #userTabs="text">
          <span v-for="(item, index) in text" :key="index">{{ item }}、</span>
        </template>
      </a-table>
      <div style="margin: 30px 0 50px 0">
        <label style="margin-right: 15px">选择认证类型</label>
        <a-select
          allowClear
          v-model="authType"
          placeholder="请选择"
          style="width: 220px"
        >
          <template v-if="userType === '0'">
            <a-select-option
              v-for="item in typeOpt.userTypes"
              :key="item.value"
              :value="item.value"
              >{{ item.text }}
            </a-select-option>
          </template>
          <template v-if="userType === '1'">
            <a-select-option
              v-for="item in typeOpt.creativeTypes"
              :key="item.value"
              :value="item.value"
              >{{ item.text }}
            </a-select-option>
          </template>
          <template v-if="userType === '2'">
            <a-select-option
              v-for="item in typeOpt.independentTypes"
              :key="item.value"
              :value="item.value"
            >
              {{ item.text }}
            </a-select-option>
          </template>
        </a-select>
        <label
          style="margin-right: 15px; margin-left: 30px"
          v-if="userType !== '0'"
          >选择认证平台</label
        >
        <a-select
          allowClear
          v-model="platformType"
          placeholder="请选择"
          style="width: 220px"
          v-if="userType !== '0'"
        >
          <a-select-option
            v-for="(item, idx) in platformTypes"
            :key="idx"
            :value="item.userId"
          >
            {{ item.shopName }}
          </a-select-option>
        </a-select>
      </div>
      <div
        style="
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 0 30px 0;
        "
      >
        <a-button style="width: 20%" type="primary" @click="sendInsertPage"
          >确认</a-button
        >
        <a-button style="margin-left: 15px" @click="modal.infoVisible = false"
          >取消</a-button
        >
      </div>
    </a-modal>

    <!-- 未找到手机号 弹窗 -->
    <NotPhoneModal
      :visible="modal.sureVisible"
      @closeNotPhone="modal.sureVisible = false"
    />

    <!--审核 弹窗-->
    <ApplyStatusModal
      :visible="modal.auditVisible"
      :applyRow="applyRow"
      @refresh="queryData"
      @cancelStatus="verifyAudit"
    />
  </ak-container>
</template>

<script>
import * as Settle from '@/api/user/settle'
import { message } from 'ant-design-vue'
import ApplyStatusModal from '@/components/ApplyStatusModal'
import NotPhoneModal from '@/components/NotPhoneModal'

const columns = [
  {
    title: '用户信息',
    dataIndex: 'bindPersonalResp',
    scopedSlots: {
      customRender: 'bindPersonalResp',
    },
    align: 'center',
    width: 200,
  },
  {
    title: '认证类型',
    dataIndex: 'authenticationType',
    scopedSlots: {
      customRender: 'authenticationType',
    },
    align: 'center',
    width: 160,
  },
  {
    title: '状态',
    dataIndex: 'state',
    scopedSlots: {
      customRender: 'state',
    },
    align: 'center',
    width: 80,
  },
  {
    title: '最后IP属地',
    dataIndex: 'ipLocation',
    align: 'center',
    width: 120,
  },
  {
    title: '申请时间',
    dataIndex: 'applyTime',
    align: 'center',
    defaultSortOrder: 'descend', // 默认上到下为由大到小的顺序
    sorter: (a, b) => {
      return a.applyTime > b.applyTime ? 1 : -1
    },
    width: 180,
  },
  {
    title: '认证时间',
    dataIndex: 'authenticationTime',
    align: 'center',
    defaultSortOrder: 'descend', // 默认上到下为由大到小的顺序
    sorter: (a, b) => {
      return a.authenticationTime > b.authenticationTime ? 1 : -1
    },
    width: 180,
  },
  {
    title: '操作',
    fixed: 'right',
    dataIndex: 'action',
    scopedSlots: {
      customRender: 'action',
    },
    align: 'center',
    width: 200,
  },
]

const columns1 = [
  {
    title: 'LOGO',
    dataIndex: 'logo',
    scopedSlots: {
      customRender: 'logo',
    },
    width: 120,
  },
  {
    title: '名称',
    dataIndex: 'shopName',
    align: 'center',
    width: 180,
  },
  {
    title: '用户信息',
    dataIndex: 'bindPersonalResp',
    scopedSlots: {
      customRender: 'bindPersonalResp',
    },
    align: 'center',
    width: 200,
  },
  {
    title: '认证类型',
    dataIndex: 'authenticationType',
    scopedSlots: {
      customRender: 'authenticationType',
    },
    align: 'center',
    width: 160,
  },
  {
    title: '状态',
    dataIndex: 'state',
    scopedSlots: {
      customRender: 'state',
    },
    align: 'center',
    width: 80,
  },
  {
    title: '最后IP属地',
    dataIndex: 'ipLocation',
    align: 'center',
    width: 120,
  },
  {
    title: '申请时间',
    dataIndex: 'applyTime',
    align: 'center',
    defaultSortOrder: 'descend', // 默认上到下为由大到小的顺序
    sorter: (a, b) => {
      return a.applyTime > b.applyTime ? 1 : -1
    },
    width: 180,
  },
  {
    title: '认证时间',
    dataIndex: 'authenticationTime',
    align: 'center',
    defaultSortOrder: 'descend', // 默认上到下为由大到小的顺序
    sorter: (a, b) => {
      return a.authenticationTime > b.authenticationTime ? 1 : -1
    },
    width: 180,
  },
  {
    title: '操作',
    fixed: 'right',
    dataIndex: 'action',
    scopedSlots: {
      customRender: 'action',
    },
    align: 'center',
    width: 200,
  },
]

const columns2 = [
  {
    title: 'LOGO',
    dataIndex: 'logo',
    scopedSlots: {
      customRender: 'logo',
    },
    width: 120,
  },
  {
    title: '店铺名称',
    dataIndex: 'shopName',
    align: 'center',
    width: 180,
  },
  {
    title: '用户信息',
    dataIndex: 'bindPersonalResp',
    scopedSlots: {
      customRender: 'bindPersonalResp',
    },
    align: 'center',
    width: 200,
  },
  {
    title: '认证类型',
    dataIndex: 'authenticationType',
    scopedSlots: {
      customRender: 'authenticationType',
    },
    align: 'center',
    width: 160,
  },
  {
    title: '状态',
    dataIndex: 'state',
    scopedSlots: {
      customRender: 'state',
    },
    align: 'center',
    width: 80,
  },
  {
    title: '最后IP属地',
    dataIndex: 'ipLocation',
    align: 'center',
    width: 120,
  },
  {
    title: '申请时间',
    dataIndex: 'applyTime',
    align: 'center',
    defaultSortOrder: 'descend', // 默认上到下为由大到小的顺序
    sorter: (a, b) => {
      return a.applyTime > b.applyTime ? 1 : -1
    },
    width: 180,
  },
  {
    title: '认证时间',
    dataIndex: 'authenticationTime',
    align: 'center',
    defaultSortOrder: 'descend', // 默认上到下为由大到小的顺序
    sorter: (a, b) => {
      return a.authenticationTime > b.authenticationTime ? 1 : -1
    },
    width: 180,
  },
  {
    title: '操作',
    fixed: 'right',
    dataIndex: 'action',
    scopedSlots: {
      customRender: 'action',
    },
    align: 'center',
    width: 200,
  },
]

const infoColumns = [
  {
    title: 'LOGO',
    dataIndex: 'logo',
    scopedSlots: {
      customRender: 'logo',
    },
    width: 120,
  },
  {
    title: '名称',
    dataIndex: 'shopName',
    width: 120,
  },
  {
    title: '头像',
    dataIndex: 'headImage',
    scopedSlots: {
      customRender: 'headImage',
    },
    align: 'center',
  },
  {
    title: '账号昵称',
    dataIndex: 'name',
    align: 'center',
  },
  {
    title: '联系方式',
    dataIndex: 'phone',
    align: 'center',
  },
  {
    title: '性别',
    dataIndex: 'sex',
    scopedSlots: {
      customRender: 'sex',
    },
    align: 'center',
  },
  {
    title: 'Ta的兴趣',
    dataIndex: 'userTabs',
    scopedSlots: {
      customRender: 'userTabs',
    },
    align: 'center',
  },
  {
    title: '发图文',
    dataIndex: 'issueImageNum',
    align: 'center',
  },
  {
    title: '发视频',
    dataIndex: 'issueVideoNum',
    align: 'center',
    widht: 100,
  },
  {
    title: '发文章',
    dataIndex: 'issueArticleNum',
    align: 'center',
    widht: 100,
  },
  {
    title: '发作品',
    dataIndex: 'issueWorksNum',
    align: 'center',
    widht: 100,
  },
  {
    title: '创建时间',
    dataIndex: 'createdTime',
    align: 'center',
  },
]
export default {
  components: {
    ApplyStatusModal,
    NotPhoneModal,
  },
  data() {
    return {
      userType: '0',
      authType: '',
      userPhone: '',
      typeOpt: {
        userTypes: [
          {
            value: '1',
            text: '实名认证',
          },
          {
            value: '2',
            text: '身份认证',
          },
        ],
        creativeTypes: [
          {
            value: '2',
            text: '身份认证',
          },
          {
            value: '4',
            text: '品牌故事',
          },

          {
            value: '5',
            text: '品牌认证',
          },
          {
            value: '6',
            text: '经营地址认证',
          },
          {
            value: '9',
            text: '办公环境认证',
          },
          {
            value: '11',
            text: '展览认证',
          },
          {
            value: '12',
            text: '经历认证',
          },
          {
            value: '13',
            text: '获奖认证',
          },
          {
            value: '14',
            text: '主页名称',
          },
          {
            value: '15',
            text: '简介认证',
          },
        ],
        independentTypes: [
          {
            value: '2',
            text: '身份认证',
          },
          {
            value: '4',
            text: '品牌故事',
          },
          {
            value: '6',
            text: '经营地址认证',
          },
          {
            value: '10',
            text: '店铺环境认证',
          },
          {
            value: '11',
            text: '展览认证',
          },
          {
            value: '12',
            text: '经历认证',
          },
          {
            value: '13',
            text: '获奖认证',
          },
          {
            value: '14',
            text: '主页名称',
          },
          {
            value: '15',
            text: '简介认证',
          },
        ],
      },
      allType: [
        {
          value: '1',
          text: '实名认证',
        },
        {
          value: '2',
          text: '身份认证',
        },
        {
          value: '3',
          text: '企业真实性验证',
        },
        {
          value: '4',
          text: '品牌故事',
        },
        {
          value: '5',
          text: '品牌认证',
        },
        {
          value: '6',
          text: '经营地址认证',
        },
        {
          value: '7',
          text: '办公地址认证',
        },
        {
          value: '8',
          text: '线下实体店认证',
        },
        {
          value: '9',
          text: '办公环境认证',
        },
        {
          value: '10',
          text: '店铺环境认证',
        },
        {
          value: '11',
          text: '展览认证',
        },
        {
          value: '12',
          text: '经历认证',
        },
        {
          value: '13',
          text: '获奖认证',
        },
        {
          value: '14',
          text: '主页名称',
        },
        {
          value: '15',
          text: '简介认证',
        },
      ],
      modal: {
        phoneVisible: false,
        infoVisible: false,
        sureVisible: false,
        auditVisible: false,
      },
      pagination: {
        total: 0,
        pageSize: 10, // 每页中显示10条数据
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '50', '100'], // 每页中显示的数据
        showTotal: (total) => `共有 ${total} 条数据`, // 分页中显示总的数据
        current: 1,
      },
      // 查询参数
      queryFilters: {
        page: 1,
        pageSize: 10,
        businessType: 1,
        businessTag: 1,
        date: [],
      },
      userColumns: [
        // {
        //   title: '用户信息',
        //   dataIndex: 'bindPersonalResp',
        //   scopedSlots: {customRender: 'bindPersonalResp'},
        //   align: 'center',
        //   width: 200
        // },
      ],
      creativeColumns: [
        {
          title: 'LOGO',
          dataIndex: 'logo',
          scopedSlots: {
            customRender: 'logo',
          },
          align: 'center',
          width: 200,
        },
        {
          title: '名称',
          dataIndex: 'name',
          align: 'center',
          width: 200,
        },
        {
          title: '绑定用户',
          dataIndex: 'userInfo',
          scopedSlots: {
            customRender: 'userInfo',
          },
          align: 'center',
          width: 200,
        },
      ],
      independentColumns: [
        {
          title: 'LOGO',
          dataIndex: 'logo',
          scopedSlots: {
            customRender: 'logo',
          },
          align: 'center',
          width: 200,
        },
        {
          title: '店铺名称',
          dataIndex: 'name',
          align: 'center',
          width: 200,
        },
        {
          title: '绑定用户',
          dataIndex: 'userInfo',
          scopedSlots: {
            customRender: 'userInfo',
          },
          align: 'center',
          width: 200,
        },
      ],
      columns,
      columns1,
      columns2,
      infoColumns,
      data: [],
      tableList: [],
      infoData: [],
      platformOpts: [
        {
          value: 0,
          label: '创作者平台',
        },
        {
          value: 1,
          label: '商家平台',
        },
        {
          value: 2,
          label: '小程序',
        },
      ],
      statusOpts: [
        {
          value: 0,
          label: '审核中',
        },
        {
          value: 1,
          label: '已通过',
        },
        {
          value: 2,
          label: '未通过',
        },
      ],
      platformType: '',
      platformTypes: [],
      applyRow: null,
    }
  },
  created() {
    this.search()
  },
  methods: {
    getPlatByUserId(userid) {
      let that = this
      Settle.getPlatByUserId({
        tag: 1,
        type: that.userType,
        userId: userid,
      })
        .then((res) => {
          if (res.code === 0) {
            that.platformTypes = res.data
          }
        })
        .catch((e) => {})
        .finally(() => {})
    },
    afterClose() {
      this.userPhone = ''
      this.modal.phoneVisible = false
    },
    afterCloseinfo() {
      this.userPhone = ''
      this.modal.infoVisible = false
    },
    // 表格分页条件、筛选条件、排序条件发生变化后刷新表格数据
    handleTableChange(pagination, filters, sorter) {
      let that = this
      that.sortedInfo = sorter
      const pager = {
        ...that.pagination,
      }
      pager.current = pagination.current
      pager.pageSize = pagination.pageSize
      that.pagination = pager
      this.queryFilters.page = pagination.current
      this.queryFilters.pageSize = pagination.pageSize
      this.queryFilters.applyTimeOrder = ''
      this.queryFilters.settledTimeOrder = ''
      if (sorter && sorter.order) {
        if (sorter.field == 'applyTime') {
          if (sorter.order == 'ascend') {
            this.queryFilters.applyTimeOrder = 'asc'
          }
          if (sorter.order == 'descend') {
            this.queryFilters.applyTimeOrder = 'desc'
          }
        }
        if (sorter.field == 'authenticationTime') {
          if (sorter.order == 'ascend') {
            this.queryFilters.settledTimeOrder = 'asc'
          }
          if (sorter.order == 'descend') {
            this.queryFilters.settledTimeOrder = 'desc'
          }
        }
      }
      that.queryData()
    },
    tabsChange(key) {
      if (this.queryFilters.authenticationType) {
        this.queryFilters = {
          page: 1,
          pageSize: 10,
          businessType: this.queryFilters.businessType,
          businessTag: this.queryFilters.businessTag,
          date: this.queryFilters.date,
        }
      }
      this.queryData()
    },
    // 加载数据方法
    queryData() {
      this.loading = true
      let date = {
        page: this.queryFilters.page,
        pageSize: this.queryFilters.pageSize,
        phone: this.queryFilters.phone,
        name: this.queryFilters.name,
        authenticationType: this.queryFilters.authenticationType,
        businessType: Number(this.userType),
        businessTag: this.userType === '0' ? 0 : 1,
        applyState: this.queryFilters.applyState,
        createdTimeStart: this.queryFilters.settleTime
          ? this.queryFilters.settleTime[0]
          : '',
        createdTimeEnd: this.queryFilters.settleTime
          ? this.queryFilters.settleTime[1]
          : '',
        applyTimeOrder: this.queryFilters.applyTimeOrder,
        settledTimeOrder: this.queryFilters.settledTimeOrder,
      }
      Settle.queryAuthenticationPage(date)
        .then((res) => {
          this.tableList = res.data.list
          this.pagination.total = res.data.total
          this.loading = false
        })
        .catch((e) => {
          this.message = e.message || '查询错误'
        })
        .finally(() => {
          this.loading = false
        })
      this.loading = false
    },
    getUserByPhone() {
      let param = {}
      if (this.userType === '0') {
        param = {
          phone: this.userPhone,
        }
      } else {
        param = {
          phone: this.userPhone,
          businessTag: 1,
          businessType: this.userType,
        }
      }
      let that = this
      Settle.queryHkyPersonal(param)
        .then((res) => {
          if (res.data) {
            that.infoData = [res.data]
            
            that.getPlatByUserId(res.data.userId)
          }
          if (res.code == 0) {
            that.modal.phoneVisible = false
            that.modal.infoVisible = true
          } else {
            that.modal.sureVisible = true
          }
        })
        .catch((e) => {
          that.message = e.message || '查询错误'
        })
        .finally(() => {})
    },
    search() {
      this.pagination.current = 1
      this.queryFilters.page = 1
      this.queryData()
    },
    // 重置
    onResetForm() {
      this.$refs.form.resetFields()
      this.queryFilters = this.$options.data.call(this).queryFilters
      this.queryData()
    },
    audit(row) {
      this.applyRow = row
      this.modal.auditVisible = true
    },
    verifyAudit() {
      this.applyRow = null
      this.modal.auditVisible = false
    },
    checkPhone() {
      let strTemp = /^1[3|4|5|6|7|8|9][0-9]{9}$/
      if (strTemp.test(this.userPhone)) {
        this.platformType = ''
        this.getUserByPhone()
      } else {
        this.modal.sureVisible = true
        return false
      }
    },
    sendInsertPage() {
      if (!this.authType) {
        return message.error('请选择认证类型')
      }
      console.log(this.infoData,'===>')
      const params = {
        authType: this.authType,
        userType: this.userType,
        id: this.infoData[0].userId,
        userPhone: this.infoData[0].phone,
      }
      console.log(this.platformType, this.userType)
      if (!this.platformType && this.userType !== '0') {
        return message.error('请选择认证平台')
      } else {
        params.bsId = this.platformType
      }
      this.$router.push({
        path: '/user-manage/oneself-audit/insert',
        query: params,
      })
    },
    sendInsertdetail(record) {
      this.$router.push({
        path: '/user-manage/oneself-audit/detail',
        query: {
          authType: record.authenticationType,
          userType: this.userType,
          id: record.id,
          userId: record.userId,
          pathName: this.allType.filter(
            (item, index) => item.text === record.authenticationType?.value
          ),
        },
      })
    },
    sendInsertedit(record) {
      this.$router.push({
        path: '/user-manage/oneself-audit/insert',
        query: {
          isedit: 1,
          authType: record.authenticationType,
          userType: this.userType,
          id: record.id,
          userId: record.userId,
          
          pathName: this.allType.filter(
            (item, index) => item.text === record.authenticationType?.value
          ),
        },
      })
    },
  },
}
</script>
<style lang="less" scoped>
/deep/ .ant-card-body {
  padding: 18px;
}

/deep/ .ant-col-xl-8 {
  padding-left: 0 !important;
}

/deep/ .ant-space {
  width: 96%;
  display: flex;
  margin: 10px auto;
}

/deep/ .ant-form-item-label-left {
  text-align: left !important;
}

.settime {
  width: 100% !important;
}
</style>
